import Alpine from "alpinejs";

Alpine.data("toggle", () => ({
  name: "",
  checked: false,

  init() {
    this.name = this.$refs.input.name;
    this.checked = this.$refs.input.value === "true";
  },

  toggle() {
    this.checked = !this.checked;
    this.$refs.input.value = this.checked;

    this.$dispatch(`toggle-changed`, {
      name: this.name,
      checked: this.checked,
    });
  },
}));
