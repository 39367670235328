import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";

export default class extends Controller {
  static values = {
    type: String,
  };

  connect() {
    const config =
      this.typeValue === "date" ? this.dateConfig() : this.timeConfig();

    this.datePicker = flatpickr(this.element, config);
  }

  dateConfig() {
    return {
      locale: Portuguese,
      dateFormat: "d/m/Y",
    };
  }

  timeConfig() {
    return {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    };
  }
}
