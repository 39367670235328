import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.modal = this.element;
    this.modal.focus();
  }

  close() {
    this.modal.close();
  }
}
