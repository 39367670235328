import Alpine from "alpinejs";

Alpine.data("select", () => ({
  open: false,
  items: [],
  selected: null,
  emptyMessage: "",

  init() {
    this.items = JSON.parse(this.$refs.data.getAttribute("data"));
    this.emptyMessage = this.$refs.emptyMessage.getAttribute("data");

    if (this.items.length > 0 && !this.selected) {
      this.setSelected(this.items[0]);
    }
  },

  toggle() {
    if (this.open) {
      return this.close();
    }

    if (this.items.length == 0) {
      return;
    }

    this.open = true;
    this.$refs.button.focus();
  },

  handleSelect(item) {
    this.setSelected(item);
    this.toggle();
  },

  setSelected(item) {
    this.selected = item;
    this.$refs.input.value = item.value;
  },

  close(focusAfter) {
    if (!this.open) return;

    this.open = false;

    focusAfter && focusAfter.focus();
  },
}));
