import "./flash_message/controller";
import "./mobile_navigation/controller";
import "./select/controller";
import "./sidebar/controller";
import "./toggle/controller";

import { application } from "../assets/javascript/controllers/application";
import FullCalendarController from "./calendar_full/controller";
import DatePickerController from "./date_picker/controller";
import ModalController from "./modal/controller";

application.register("date-picker", DatePickerController);
application.register("modal", ModalController);
application.register("fullcalendar", FullCalendarController);
