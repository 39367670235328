import Alpine from "alpinejs";

Alpine.data("flash_message", () => ({
  show: true,

  init() {
    setTimeout(() => {
      this.close();
    }, 8000);
  },

  close() {
    this.show = false;
  },
}));
